html {
  box-sizing: border-box;
  overflow-y: scroll;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 62.5%;
  /* BETTER FONT SMOOTHING - https://gist.github.com/hsleonis/55712b0eafc9b25f1944 */
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: #f2f4f8;
}

::-webkit-scrollbar-thumb {
  background-color: #141213;
  border: 2px solid #262626;
}

a {
  color: rebeccapurple;
  text-decoration: none;

  &:active,
  &:focus,
  &:hover {
    text-decoration: underline;
  }
}

body {
  scroll-behavior: smooth;

  background-color: #f2f4f8;
  font-size: 1.6rem;
  line-height: 1.5;
}



/* Common base styles for the site */
img,
svg,
video {
  max-width: 100%;
}
