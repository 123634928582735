.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#root,
.App,
.App > div,
.outer-wrap,
.ant-row,
.ant-col {
  height: 100%;
}

@media (min-width: 920px) {
  .ant-col.data {
    max-width: 40% !important;
  }
}

.registration-form-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* 
.App {
  width: 100%;
  height: 100%; 
}

.outer-wrap {
  display: flex;
  width: 100%;
  height: 100%;
}

.left-wrap {
  width: 60%;
  height: 100%;
}

.right-wrap {
  width: 40%;
  height: 100%;
} */

/* for CSV download link */
.download-csv {
  color: black;
  font-size: 1rem;
  position: absolute;
  transform: translateX(800%) translateY(3000%);
}